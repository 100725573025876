import request from './request'

const api = {
	// 后台登录
	login: '/System/login',
	// 后台登录
	sign: '/Test/sign',
}

export {
	request,
	api
}
